
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable_coursesector.vue';
import AddMilestone from '@/layout/header/partials/course/AddMilestone.vue';
import EditMilestone from '@/layout/header/partials/course/EditMilestone.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export default defineComponent({
  name: 'course-sector',
  components: {
    Datatable,
    AddMilestone,
    EditMilestone,
  },
  data() {
    return {
      componentKey: 0,
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '120px',
        },

        {
          name: 'Training Milestone',
          key: 'milestone_type',
          sortable: true,
        },
      ],
      lists: [],
      search: '',
      tableData: [],
      data: {},
    };
  },
  async created() {
    await this.getMilestoneData();
    Object.assign(this.tableData, this.lists);

    this.emitter.on('milestone-added', async () => {
      await this.getMilestoneData();
      this.tableData = this.lists;
      this.componentKey += 1;
    });
  },
  methods: {
    async getMilestoneData() {
      await ApiService.get('configurations/milestone_type/list')
        .then((response) => {
          this.lists = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    editmilestone(data) {
      this.emitter.emit('milestone-edit-type', data);
    },

    Deletemilestone(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('configurations/milestone_type/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('milestone-added', true);
              Swal.fire('Deleted!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
